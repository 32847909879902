import { ArrowLeft24Regular, MoreVertical20Regular } from '@fluentui/react-icons';
import { Button, Divider, Grow, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageWithAppBar from '../components/PageWithAppBar';

import {
  // COMPONENTS
  Box,
  DisposalRequestApprovalDialog,
  DisposalRequestDelegationDialog,
  DisposalRequestDetailsPanel,
  DisposalRequestHeaderActionButtons,
  DisposalRequestRejectionDialog,
  DisposalRequestSuccessAlert,
  DisposalRequestWarningAlert,
  FullModalDialog,
  HomeButton,
  Indicator,
  ItemPanel,
  ItemsGrid,
  OpenInContextMenuTarget,
  TabPanel,

  // CONSTANTS
  ALERT_TEXTS,
  TEST_CONSTANTS,

  // HOOKS
  useApiAlert,
  useDisposalRequest,
  useUser,

  // ICONS
  DisposalRequest64,

  // SERVICES
  useApproveDisposalRequestMutation,
  useDelegateDisposalRequestMutation,
  useGetApproversListQuery,
  useRejectDisposalRequestMutation,

  // STORE
  setCurrentItem,
  useAppDispatch,
  useAppSelector,

  // TYPES
  ActionHandler,
  ActionParams,
  Approver,
  DisposalRequestResponseStatus,
  DisposalRequestStatus,
  Item,
  ItemGridQuery,
  ItemLevel,

  // UTILS
  handleAction,
  isUserInPending,
} from '@encompaas/common';

const DisposalRequestPage = () => {
  // Navigation and Parameter Retrieval
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const dispatch = useAppDispatch();

  // User Information
  const user = useUser();

  //refs
  const alertShownRef = useRef(false);
  const userApiErrorAlertShownRef = useRef(false);
  // Tabs and Modals States
  const [selectedTab, setSelectedTab] = useState('details');
  const [approvalDialogOpen, setApprovalDialogOpen] = useState<boolean>(false);
  const [rejectionDialogOpen, setRejectionDialogOpen] =
    useState<boolean>(false);
  const [delegationDialogOpen, setDelegationDialogOpen] =
    useState<boolean>(false);

  const [tabIntent, setTabIntent] = useState<'items' | undefined>(undefined);

  // Context Menu State
  const [contextAnchor, setContextAnchor] = useState<Element | null>(null);

  // Item Identification
  const [itemID, setItemID] = useState<string | undefined>();

  // Alert States
  const { errorAlert, successAlert } = useApiAlert();

  // Mutations
  const [approveDisposalRequest] = useApproveDisposalRequestMutation();
  const [rejectDisposalRequest] = useRejectDisposalRequestMutation();
  const [delegateDisposalRequest] = useDelegateDisposalRequestMutation();

  //Queries
  const {
    data: disposalRequest,
    isSuccess,
    isError: disposalRequestError,
  } = useDisposalRequest({ id, userApiError: user?.isUserApiError });
  const { data: approvers } = useGetApproversListQuery();

  //Query related informations
  const status =
    !!user && isUserInPending(user, disposalRequest?.ApproverResponses?.value)
      ? DisposalRequestResponseStatus.Pending
      : undefined;

  const [currentSelectedApprovers, setCurrentSelectedApprovers] = useState<
    Approver[]
  >([]);

  // Effects
  useEffect(() => {
    const _selectedTab = queryParams.get('tab') ?? 'details'; // Getting the current tab from query params
    if (_selectedTab !== selectedTab) {
      // If the current tab does not match the selected tab, update the selected tab
      setSelectedTab(_selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    const isUserValid = user?.username;
    const isDisposalRequestInApproval =
      disposalRequest?.Status === DisposalRequestStatus.InApproval;
    const currentApproverStatus =
      disposalRequest?.ApproverResponses?.value.find(
        (approver) => approver?.ApproverId === user?.ID
      )?.Status ?? '';

    if (
      isUserValid &&
      isDisposalRequestInApproval &&
      currentApproverStatus === 'Waiting' &&
      !status &&
      !alertShownRef.current
    ) {
      alertShownRef.current = true;
      errorAlert.setError(
        'Response submission failure',
        'You are not the current approver for this Disposal Request. Check the Disposal Request details tab to see when you become so.'
      );
    } else if (
      alertShownRef.current &&
      (!isUserValid || !isDisposalRequestInApproval || status)
    ) {
      alertShownRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, disposalRequest, status]);

  useEffect(() => {
    if (disposalRequestError) {
      if (!alertShownRef.current) {
        errorAlert.setError(
          'Response submission failure',
          'Your response submission failed. Please try again, or contact your Administrator.'
        );
        alertShownRef.current = true;
      }
    } else {
      alertShownRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposalRequestError]);

  useEffect(() => {
    if (user?.isUserApiError) {
      if (!userApiErrorAlertShownRef.current) {
        errorAlert.setError(
          'Cannot open Disposal Request',
          'It may have been deleted or reassigned to other approvers.'
        );
        userApiErrorAlertShownRef.current = true;
      }
    } else {
      userApiErrorAlertShownRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isUserApiError]);

  // Setting the approvers
  useEffect(() => {
    const _currentSelectedApprovers: Approver[] =
      disposalRequest?.ApproverResponses?.value.map(
        (_approverResponse, index) => ({
          _Display: _approverResponse.Approver?.DisplayName!,
          ID: _approverResponse.Approver?.ID!,
          ApproverId: _approverResponse.ID,
          Department: '',
          Status: _approverResponse.Status,
          Order: _approverResponse.Order,
        })
      ) ?? [];

    setCurrentSelectedApprovers(_currentSelectedApprovers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, disposalRequest]);

  const handleHome = () => {
    //if history param is set then we can go back, otherwise go home
    !!queryParams.get('history') ? navigate(-1) : navigate('/');
  };

  const handleSelectTab = (tab: string) => {
    //this will reset the history param so that the home button will go home rather than back
    setQueryParams({ tab });
  };

  function createActionHandler(
    action: any,
    failureAlertText: { title: string; message: string },
    successAlertText: { title: string; message: string },
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  ): ActionHandler {
    return async (comment: string, delegateToUser?: string) => {
      const params: ActionParams = { comment: comment, requestId: id || '' };
      if (delegateToUser) {
        params.delegateToUserId = delegateToUser;
      }

      await handleAction(
        params,
        action,
        failureAlertText,
        successAlertText,
        setDialogOpen,
        errorAlert,
        successAlert
      );
    };
  }

  const handleApproveAction = createActionHandler(
    approveDisposalRequest,
    ALERT_TEXTS.approve.failure,
    ALERT_TEXTS.approve.success,
    setApprovalDialogOpen
  );

  const handleRejectAction = createActionHandler(
    rejectDisposalRequest,
    ALERT_TEXTS.reject.failure,
    ALERT_TEXTS.reject.success,
    setRejectionDialogOpen
  );

  const handleDelegateAction = createActionHandler(
    delegateDisposalRequest,
    ALERT_TEXTS.delegate.failure,
    ALERT_TEXTS.delegate.success,
    setDelegationDialogOpen
  );

  const navigateBreadcrumbs = (item: Item) => {
    if (gridRef.current) {
      setItemID(item.ID);
    }
  };

  const handleOpen = (item: Item, target?: OpenInContextMenuTarget, initialTab?: string) => {
    dispatch(setCurrentItem({ id: item.ID, itemId: item.ID, name: item.DisplayName, initialTab }));
    const _target = target ?? 'modal';
    switch (_target) {
      case 'new tab':
      case 'new window':
        window.open(
          `/item/${item.ID}?requestId=${id}${
            (disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
            ''
          }`,
          '_blank'
        );
        break;
      case 'chromeless page':
        window.open(`/itempanel/${item.ID}`, '_blank');
        break;
      case 'modal':
        navigateBreadcrumbs(item);
        break;
      case 'new page':
      default:
        navigate(
          `/item/${item.ID}?requestId=${id}${
            (disposalRequest?.Name && '&requestName=' + disposalRequest.Name) ??
            ''
          }`
        );
        break;
    }
  };

  const handleOpenItems = (item: Item, target?: OpenInContextMenuTarget) => {
    setTabIntent('items');
    setSelectedTab('items');
    handleOpen(item, 'modal', 'items');
  };

  const [selectedCheckbox, setSelectedCheckbox] = useState<any[]>([]);
  const handleCheckbox = (item: any) => {
    setSelectedCheckbox(item);
    console.log('Checkbox', item);
  };

  const handleOpenURL = (url?: string) => {
    if (url) window.open(url, '_blank');
  };

  const gridRef = useRef(null);

  const showColumnChooser = () => {
    if (gridRef?.current) {
      (gridRef.current as unknown as any).openColumnChooser(0, 0);
    }
  };

  const Grid = useMemo(() => {
    if (disposalRequest) {
      return (
        <ItemsGrid
          onOpen={handleOpen}
          onOpenURL={handleOpenURL}
          onOpenItems={handleOpenItems}
          onSelect={handleCheckbox}
          disposalId={id!}
          disposalRequest={disposalRequest}
          selectedRecords={selectedCheckbox}
          persistanceId={`items-grid-203`}
          ref={gridRef}
          itemGridType={
            disposalRequest.Status === DisposalRequestStatus.Complete
              ? ItemGridQuery.RootWithStatus
              : ItemGridQuery.Root
          }
        />
      );
    } else {
      return <></>;
    }
  }, [id, disposalRequest]);
  return (
    <PageWithAppBar data-testid={TEST_CONSTANTS.REQUEST_PAGE}>
      <DisposalRequestWarningAlert
        onClose={errorAlert.closeAlert}
        open={errorAlert.isOpen}
        title={errorAlert.title}
        message={errorAlert.message}
      />
      <DisposalRequestSuccessAlert
        onClose={successAlert.closeAlert}
        open={successAlert.isOpen}
        title={successAlert.title}
        message={successAlert.message}
      />
      <DisposalRequestApprovalDialog
        request={disposalRequest}
        open={approvalDialogOpen}
        user={user}
        onClose={() => {
          setApprovalDialogOpen(false);
        }}
        onAction={handleApproveAction}
      />
      <DisposalRequestRejectionDialog
        request={disposalRequest}
        open={rejectionDialogOpen}
        user={user}
        onClose={() => {
          setRejectionDialogOpen(false);
        }}
        onAction={handleRejectAction}
      />
      <DisposalRequestDelegationDialog
        request={disposalRequest}
        approvers={approvers}
        open={delegationDialogOpen}
        user={user}
        onClose={() => {
          setDelegationDialogOpen(false);
        }}
        onAction={handleDelegateAction}
      />
      <FullModalDialog
        open={!!itemID}
        title={disposalRequest?.Name}
        icon={<ArrowLeft24Regular />}
        onAction={(crumb?: ItemLevel) => {
          const newCrumb: ItemLevel = {
            ...crumb,
            id: crumb?.id ?? '',
            itemId: crumb?.itemId ?? '',
            name: crumb?.name ?? '',
            shouldExpand: false
          };
          setItemID(crumb?.itemId);
          if (newCrumb) dispatch(setCurrentItem(newCrumb));
        }}
        onClose={() => {
          setItemID(undefined);
          setTabIntent(undefined);
          dispatch(setCurrentItem(undefined));
        }}
      >
        {itemID && (
          <ItemPanel
            id={itemID}
            disposalRequest={disposalRequest}
            initialTab={tabIntent}
            setId={setItemID}
          />
        )}
      </FullModalDialog>

      <Box background='light' rounded='top' blur>
        <Box
          background='none'
          height={3.25}
          direction='row'
          justifyContent='start'
          alignItems='center'
          style={{ padding: '0 1.5rem' }}
        >
          <HomeButton
            text='Home'
            onClick={() => handleHome()}
            data-testid={TEST_CONSTANTS.HOME_BUTTON}
          />
        </Box>
        <Divider style={{ width: '100%' }} />
        <Box
          background='none'
          padding='large'
          direction='row'
          gap='large'
          height={4}
        >
          <Box shrink>
            <DisposalRequest64 />
          </Box>
          <Box background='none' justifyContent='center' gap='xsmall'>
            <Box
              background='none'
              justifyContent='start'
              alignItems='center'
              direction='row'
              gap='medium'
            >
              <Typography variant='h1'>
                {disposalRequest?.Name ?? 'Request'}
              </Typography>
              {disposalRequest?.Status === DisposalRequestStatus.Rejected && (
                <Indicator
                  label={DisposalRequestStatus.Rejected}
                  color='warning'
                  isRejected={true}
                ></Indicator>
              )}
            </Box>
            <Typography variant='h3' color={theme.palette.info.main}>
              Disposal request
            </Typography>
          </Box>

          <DisposalRequestHeaderActionButtons
            status={status}
            onApprove={() => {
              setApprovalDialogOpen(true);
            }}
            onReject={() => {
              console.log('onReject called');
              setRejectionDialogOpen(true);
            }}
            onDelegate={() => {
              setDelegationDialogOpen(true);
            }}
          />
        </Box>

        <DisposalRequestApprovalDialog
          title='Approve request'
          request={disposalRequest}
          open={approvalDialogOpen}
          user={user}
          onClose={() => {
            setApprovalDialogOpen(false);
          }}
          onAction={handleApproveAction}
        />
        <DisposalRequestRejectionDialog
          title='Reject request'
          request={disposalRequest}
          open={rejectionDialogOpen}
          user={user}
          onClose={() => {
            setRejectionDialogOpen(false);
          }}
          onAction={handleRejectAction}
        />
        <TabPanel
          selected={selectedTab}
          onSelect={handleSelectTab}
          inset={'7rem'}
          tabs={[
            {
              title: 'Details',
              value: 'details',
              children: isSuccess && !!disposalRequest && (
                <DisposalRequestDetailsPanel
                  request={disposalRequest}
                  currentSelectedApprovers={currentSelectedApprovers}
                />
              ),
            },
            {
              title: 'Items',
              value: 'items',
              children: (
                <Box
                  padding='large'
                  background='none'
                  style={{ height: '100%' }}
                >
                  <Box
                    background='none'
                    style={{ paddingTop: '0.6rem', height: '100%' }}
                  >
                    <Box
                      direction='row'
                      alignItems='center'
                      background='none'
                      height={3}
                    >
                      <Box
                        background='none'
                        gap={'small'}
                        direction='row'
                        justifyContent='end'
                      >
                        <Button
                          sx={{ paddingLeft: '1.2rem' }}
                          onClick={(e) => setContextAnchor(e.currentTarget)}
                          variant='text'
                          startIcon={<MoreVertical20Regular />}
                        />
                      </Box>
                      <Menu
                        open={Boolean(contextAnchor)}
                        anchorEl={contextAnchor}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        onClose={() => {
                          setContextAnchor(null);
                        }}
                        TransitionComponent={Grow}
                        MenuListProps={{
                          className: 'MuiMenu-list-withArrow',
                        }}
                      >
                        <MenuItem
                          sx={{
                            height: '2.75rem',
                            width: '12.5rem',
                            '&:hover': {
                              '& p': {
                                color: theme.palette.common.white,
                              },
                            },
                          }}
                          key={0}
                          onClick={() => {
                            showColumnChooser();
                            setContextAnchor(null);
                          }}
                        >
                          <Box width={2} background='none'></Box>
                          <Typography variant='body2'>
                            Change Columns
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            height: '2.75rem',
                            width: '12.5rem',
                            '&:hover': {
                              '& p': {
                                color: theme.palette.common.white,
                              },
                            },
                          }}
                          key={1}
                          onClick={() => {
                            if (gridRef?.current) (gridRef.current as any).refresh();
                            setContextAnchor(null);
                          }}
                        >
                          <Box width={2} background='none'></Box>
                          <Typography variant='body2'>Refresh</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Box
                      background='none'
                      style={{
                        paddingTop: '0.6rem',
                        overflow: 'visible',
                        height: '100%',
                      }}
                    >
                      {Grid}
                    </Box>
                  </Box>
                </Box>
              ),
            },
          ]}
        />
      </Box>
    </PageWithAppBar>
  );
};

export default DisposalRequestPage;
