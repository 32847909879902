import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Divider, Typography, TextField } from '@mui/material';
import { PeopleSwap20Regular, ErrorCircle16Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { DetailsRow } from '../DetailsRow.js';
import { Button } from '../button/Button.js';
import '../../config.js';
import { DisposalRequestResponseStatus } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import { requestApi } from '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { Box } from '../Box.js';
import { DisposalRequestResponseStatusChip } from '../chip/DisposalRequestResponseStatusChip.js';
import '../chip/ItemManagementStatusChip.js';
import '../chip/PerspectiveClassChip.js';
import { UserChip } from '../chip/UserChip.js';
import { CustomActionModal } from './CustomActionModal.js';
import { EditApprovers } from '../EditApprovers.js';

const DisposalRequestDelegationDialog = ({ title = 'Delegate', request, open, approvers, user, onClose, onAction, }) => {
    const theme = useTheme();
    const [comment, setComment] = useState('');
    const [delegateToUser, setDelegateToUser] = useState('');
    const [isApproversListValid, setIsApproversListValid] = useState(true);
    const [selectedApprovers, setSelectedApprovers] = useState([]);
    const handleUserSelection = (newApprover) => {
        setDelegateToUser(newApprover.ID);
    };
    return (jsxs(CustomActionModal, { title: title, onClose: () => {
            setDelegateToUser('');
            setComment('');
            onClose();
        }, open: open, icon: jsx(PeopleSwap20Regular, { style: { marginRight: '0.75rem' } }), actionButton: jsx(Button, { variant: 'contained', onClick: () => {
                onAction(comment, delegateToUser);
                setDelegateToUser('');
                setComment('');
                requestApi.util.invalidateTags(['DisposalRequest']);
            }, disabled: delegateToUser == '', children: "Delegate" }), children: [jsx(DetailsRow, { label: 'Request Name', data: request?.Name, size: 'small', alignData: true }), jsx(DetailsRow, { label: 'Disposal Action', data: request?.DisposalAction, size: 'small', alignData: true }), jsx(DetailsRow, { label: 'Response', data: jsx(DisposalRequestResponseStatusChip, { status: DisposalRequestResponseStatus.Delegated }), size: 'small', alignData: true }), jsx(DetailsRow, { label: 'Response By', data: jsx(UserChip, { user: user }), size: 'small', alignData: true }), jsx(Divider, { style: { margin: '2rem 0 2rem 0' } }), jsx(Typography, { variant: 'body1', children: "Approver" }), jsx(EditApprovers, { approvers: approvers, selectedApprovers: selectedApprovers, setSelectedApprovers: setSelectedApprovers, isApproversListValid: isApproversListValid, setIsApproversListValid: setIsApproversListValid, onSelect: handleUserSelection, singleApprovalSelect: true }), !isApproversListValid && (jsxs(Box, { background: 'none', color: 'red', direction: 'row', style: {
                    marginTop: '0.75rem',
                }, children: [jsx(ErrorCircle16Regular, { color: theme.palette.warning.main, style: {
                            marginRight: '.3rem',
                        } }), jsx(Typography, { variant: 'body3', color: theme.palette.warning.main, children: "Please specify approvers" })] })), jsx(Typography, { variant: 'body1', style: { marginTop: '1.5rem' }, children: "Comment" }), jsx(TextField, { sx: {
                    marginTop: '0.5rem',
                }, fullWidth: true, maxRows: 5, minRows: 5, multiline: true, onChange: (event) => setComment(event.target.value), value: comment })] }));
};

export { DisposalRequestDelegationDialog, DisposalRequestDelegationDialog as default };
